.blue-dark-bg {
  background-color: $blue-dark;

  hr {
    background-color: $white;
  }

  h3 {
    color: $white;
  }

  h5 {
    color: $white;
  }

  p {
    color: $white;
  }

  a,
  a.nav-link {
    color: $white;
  }
}

.blue-bg {
  background-color: $blue;
}

.orange-bg {
  background-color: $orange;
}

.yellow-bg {
  background-color: $yellow;
}


.blue-color {
  color: $blue;
}

.orange-color {
  color: $orange;
}

.yellow-color {
  color: $yellow;
}


.tooltip-a {
  .icon-info {
    font-size: 1.25rem;
    &:focus {
      outline: none;
    }
  }
}

.blue {
  .tooltip-a {
    .icon-info {
      background-color: #0f2566;
      border-radius: 50%;
      color: #fff;
    }
  }
}

.orange {
  .tooltip-a {
    .icon-info {
      background-color: #ff713f;
      color: #000;
      border-radius: 50%;

    }
  }
}

.yellow {
  .tooltip-a {
    .icon-info {
      background-color: #fb0;
      border-radius: 50%;
      color: #000;
    }
  }
}