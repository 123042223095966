$blue: #0F2566;
$blue-dark: #031445;
$blue-light: #DDE4FA;

$orange: #FF713F;
$orange-dark: #F36330;
$orange-light: #FF9874;

$yellow: #FFBB00;
$yellow-dark: #FFB000;
$yellow-light: #FFD45F;

$white: #FFFFFF;
$gray: #7f7f7f;
$link-gray: #ABABAB;
$black: #000000;

$transparent-hover: rgba(221, 228, 250, 0.1);


.white {
  color: $white;
}

.light-gray {
  color: #D8D8D8;
}

.gray {
  color: #ABABAB;
}

.dark-gray {
  color: #7F7F7F;
}

.black {
  color: #000000;
}

@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);

$nunito: 'Nunito Sans', sans-serif !important;

body {
  font-family: $nunito;
  margin-left: auto;
  margin-right: auto;
}

hr {
  background-color: $black;
}

p {
  font-size: 1.12rem;
  font-weight: 300;

  b {
    font-weight: 700;
  }
}