.anda-icon-plus {
  &:before {
    content: url(/assets/img/plus.svg);
    width: 11px;
    float: left;
    margin-right: 6px;
    margin-top: 0;
  }
}

.anda-icon-bereit {
  &:before {
    content: url(/assets/img/ampel_status1.svg);
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 10px;
    margin-top: 2px;
  }
}

.anda-icon-waiting {
  &:before {
    content: url(/assets/img/ampel_status2.svg);
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 10px;
    margin-top: 2px;
  }
}

.anda-icon-abgelehnt {
  &:before {
    content: url(/assets/img/ampel_status3.svg);
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 10px;
    margin-top: 2px;
  }
}

.personenkuerzel {
  margin-bottom: 15px;
  span {
    border: 1.5px solid #000000;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    padding-top: 4px;
    margin-right: 12px;
  }
}

span.icon-notifications_ringing {
  position: relative;
  div {
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    font-size: 10px;
    padding: 6px 4px;
    text-align: center;
    position: absolute;
    top: -1px;
    right: -8px;
  }
}

@font-face {
  font-family: 'anda-icons';
  src: url('/assets/fonts/icons.eot?u3gp75');
  src: url('/assets/fonts/icons.eot?u3gp75#iefix') format('embedded-opentype'),
  url('/assets/fonts/icons.ttf?u3gp75') format('truetype'),
  url('/assets/fonts/icons.woff?u3gp75') format('woff'),
  url('/assets/fonts/icons.svg?u3gp75#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'anda-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-abbrechen:before {
  content: "\e900";
}
.icon-abbrechen_small:before {
  content: "\e901";
}
.icon-alert:before {
  content: "\e902";
}
.icon-ampel_status1 .path1:before {
  content: "\e903";
  color: rgb(210, 239, 206);
}
.icon-ampel_status1 .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(9, 105, 33);
}
.icon-ampel_status1 .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(9, 105, 33);
}
.icon-ampel_status2 .path1:before {
  content: "\e906";
  color: rgb(255, 242, 221);
}
.icon-ampel_status2 .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(247, 154, 0);
}
.icon-ampel_status2 .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(247, 154, 0);
}
.icon-ampel_status3 .path1:before {
  content: "\e909";
  color: rgb(255, 236, 236);
}
.icon-ampel_status3 .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(242, 0, 0);
}
.icon-ampel_status3 .path3:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(242, 0, 0);
}
.icon-arrow_down:before {
  content: "\e90c";
}
.icon-arrow_left:before {
  content: "\e90d";
}
.icon-arrow_right:before {
  content: "\e90e";
}
.icon-arrow_up:before {
  content: "\e90f";
}
.icon-attachment:before {
  content: "\e910";
}
.icon-auftrag .path1:before {
  content: "\e911";
  color: rgb(0, 0, 0);
}
.icon-auftrag .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-auftrag_vergeben:before {
  content: "\e913";
}
.icon-bauauftrag:before {
  content: "\e914";
}
.icon-bearbeiten:before {
  content: "\e915";
}
.icon-bestangebot:before {
  content: "\e916";
}
.icon-bestangebot_small:before {
  content: "\e917";
}
.icon-bestatigen:before {
  content: "\e918";
}
.icon-billigstangebot:before {
  content: "\e919";
}
.icon-billigstangebot_small:before {
  content: "\e91a";
}
.icon-broadcast_1:before {
  content: "\e91b";
}
.icon-bullet_point:before {
  content: "\e91c";
}
.icon-bullet_point_ag:before {
  content: "\e91d";
  color: #fb0;
}
.icon-bullet_point_an:before {
  content: "\e91e";
  color: #ff713f;
}
.icon-bullet_point_default:before {
  content: "\e91f";
  color: #0f2566;
}
.icon-caret_down_1:before {
  content: "\e920";
}
.icon-caret_left_1:before {
  content: "\e921";
}
.icon-caret_right_1:before {
  content: "\e922";
}
.icon-caret_up_1:before {
  content: "\e923";
}
.icon-dienstleistung:before {
  content: "\e924";
}
.icon-dokument:before {
  content: "\e925";
}
.icon-download_1:before {
  content: "\e926";
}
.icon-drucken:before {
  content: "\e927";
}
.icon-einstellungen:before {
  content: "\e928";
}
.icon-erfolg_outline:before {
  content: "\e929";
}
.icon-error:before {
  content: "\e92a";
}
.icon-fab:before {
  content: "\e92b";
}
.icon-filter_1:before {
  content: "\e92c";
}
.icon-hinzufugen:before {
  content: "\e92d";
}
.icon-home_1:before {
  content: "\e92e";
}
.icon-info:before {
  content: "\e92f";
}
.icon-info_fill:before {
  content: "\e930";
}
.icon-info_outline:before {
  content: "\e931";
}
.icon-kalender:before {
  content: "\e932";
}
.icon-kommentar:before {
  content: "\e933";
}
.icon-konversation:before {
  content: "\e934";
}
.icon-lieferauftrag:before {
  content: "\e935";
}
.icon-link_1:before {
  content: "\e936";
}
.icon-login_1:before {
  content: "\e937";
}
.icon-logout_1:before {
  content: "\e938";
}
.icon-nachricht:before {
  content: "\e939";
}
.icon-notification_initial:before {
  content: "\e93a";
}
.icon-notifications_ringing:before {
  content: "\e93b";
}
.icon-nummer:before {
  content: "\e93c";
}
.icon-open_access:before {
  content: "\e93d";
}
.icon-organisation_einzeln:before {
  content: "\e93e";
}
.icon-organisationen_mehrere:before {
  content: "\e93f";
}
.icon-pdf_1:before {
  content: "\e940";
}
.icon-profil_organisation:before {
  content: "\e941";
}
.icon-profil_singleuser:before {
  content: "\e942";
}
.icon-resctricted_access:before {
  content: "\e943";
}
.icon-senden:before {
  content: "\e944";
}
.icon-speichern:before {
  content: "\e945";
}
.icon-status_laufend:before {
  content: "\e946";
}
.icon-suchen:before {
  content: "\e947";
}
.icon-teilen_1:before {
  content: "\e948";
}
.icon-telefon:before {
  content: "\e949";
}
.icon-user_in:before {
  content: "\e94a";
}
.icon-user_innen:before {
  content: "\e94b";
}
.icon-vorschau_sichtbar:before {
  content: "\e94c";
}
.icon-vorschau_unsichtbar:before {
  content: "\e94d";
}
.icon-widerrufen:before {
  content: "\e94e";
}
.icon-zeit:before {
  content: "\e94f";
}