a,
a.nav-link {
  font-weight: 700;
  padding: 0 3px;
  &.blue {
    color: $black;
    &:hover,
    &:focus {
      background-color: $blue-light;
      text-decoration: none;
    }
    &:active {
      background-color: rgba(221, 228, 250, 0.5);
    }
  }
  &.orange {
    color: $black;
    &:hover,
    &:focus{
      background-color: #FFF2EC;
      text-decoration: none;
    }
    &:active {
      background-color: rgba(255, 242, 236, 0.5);
    }
  }
  &.yellow {
    color: $black;
    &:hover,
    &:focus{
      background-color: #FFF2DD;
      text-decoration: none;
    }
    &:active {
      background-color: rgba(255, 242, 221, 0.5);
    }
  }
  &:disabled {
    color: #667c91!important;
  }
}
a[disabled] {
  color: #000000!important;
  opacity: 0.3;
  pointer-events: none!important;
  &:hover,
  &:active,
  &:focus{
    background-color: initial!important;
    cursor: unset;
  }
}
.blue-dark-bg {
  a[disabled] {
    color: #ffffff!important;
    opacity: 0.4;
  }
}
button.btn,
a.btn {
  font-size: 18px;
  max-width: 100%;
  box-shadow: none!important;
  position: relative;
  font-weight: 600;

  .button-info-text {
    color: $black;
    position: absolute;
    left: 0;
    bottom: -33px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3;
  }

  &.btn-primary {
    &.blue {
      color: $white!important;
      background-color: $blue;
      border-color: $blue;
      &:hover,
      &:focus {
        background-color: #3F5185;
        border-color: #3F5185;
      }
      &:active {
        background-color: $blue-dark;
        border-color: $blue-dark;
      }
      &.anda-icon-plus {
        &:before {
          content: url(/assets/img/plus-white.svg);
        }
      }
      &:disabled {
        background: rgba(15, 37, 102, 0.4)!important;
        border-color: transparent;
        opacity: 1;
        &:before {
          opacity: 0.5;
        }
      }
    }
    &.orange {
      color: $black!important;
      background-color: $orange;
      border-color: $orange;
      &:hover,
      &:focus{
        background-color: $orange-light;
        border-color: $orange-light;
      }
      &:active{
        background-color: $orange-dark;
        border-color: $orange-dark;
      }
      &.anda-icon-plus {
        &:before {
          content: url(/assets/img/plus.svg);
        }
      }
      &:disabled {
        background: rgba(255, 113, 63, 0.4)!important;
        border-color: transparent;
        opacity: 1;
        color: rgba(0, 0, 0, 0.4)!important;
        &:before {
          opacity: 0.4;
        }
      }
    }
    &.yellow {
      color: $black!important;
      background-color: $yellow;
      border-color: $yellow;
      &:hover,
      &:focus{
        background-color: $yellow-light;
        border-color: $yellow-light;
      }
      &:active{
        background-color: $yellow-dark;
        border-color: $yellow-dark;
      }
      &.anda-icon-plus {
        &:before {
          content: url(/assets/img/plus.svg);
        }
      }
      &:disabled {
        background: rgba(255, 187, 0, 0.4)!important;
        border-color: transparent;
        opacity: 1;
        color: rgba(0, 0, 0, 0.4)!important;
        &:before {
          opacity: 0.4;
        }
      }
    }
  }
  &.btn-secondary,
  &.btn-light {
    color: $black!important;
    background-color: $white!important;
    border-color: $black!important;

    &.blue {
      background-color: $blue;
      border-color: $blue;
      &:hover,
      &:focus {
        background-color: rgba(221, 228, 250, 0.5)!important;
        &:disabled {
          background-color: transparent!important;
        }
      }
      &:active {
        background-color: rgba(221, 228, 250, 0.25)!important;
      }
      &:disabled {
        opacity: 1;
        color: rgba(0, 0, 0, 0.3)!important;
        border-color: rgba(15, 37, 102, 0.3)!important;
        &:before {
          opacity: 0.4;
        }
      }
      .button-info-text {
        color: #000;
      }
    }
    &.orange {
      border-color: $orange!important;
      &:hover,
      &:focus {
        background-color: #fff2ec!important;
        border-color: $orange;
        &:disabled {
          background-color: transparent!important;
        }
      }
      &:active {
        background-color: rgba(255, 242, 236, 0.5)!important;
      }
      &:disabled {
        opacity: 1;
        color: rgba(0, 0, 0, 0.3)!important;
        border-color: rgba(255, 113, 63, 0.3)!important;
        &:before {
          opacity: 0.4;
        }
      }
      .button-info-text {
        color: #000;
      }
    }
    &.yellow {
      border-color: $yellow!important;
      &:hover,
      &:focus{
        background-color: #fff2dd!important;
        border-color: $yellow;
        &:disabled {
          background-color: transparent!important;
        }
      }
      &:active {
        background-color: rgba(255, 242, 221, 0.5)!important;
      }
      &:disabled {
        opacity: 1;
        color: rgba(0, 0, 0, 0.3)!important;
        border-color: rgba(255, 187, 0, 0.3)!important;
        &:before {
          opacity: 0.4;
        }
      }
      .button-info-text {
        color: #000;
      }
    }
  }
  &.btn-tertiary {
    height: unset!important;
    padding: 0;
    width: unset!important;
    border-radius: 0;
    min-width: unset!important;
    min-height: 28px;
    &.blue {
      border-bottom: 1.5px solid $blue;
      color: $black!important;
      &:hover,
      &:focus {
        background-color: #DDE4FA!important;
        &:disabled {
          background-color: transparent!important;
        }
      }
      &:active {
        background-color: rgba(221, 228, 250, 0.5)!important;
      }
      &:disabled {
        &:before {
          opacity: 0.4;
        }
        opacity: 1;
        color: rgba(0, 0, 0, 0.3)!important;
        border-bottom-color: rgba(15,37,102,.3)!important;
      }
    }
    &.orange {
      border-bottom: 1.5px solid $orange;
      color: $black!important;
      &:hover,
      &:focus{
        background-color: #FFF2EC!important;
        &:disabled {
          background-color: transparent!important;
        }
      }
      &:active {
        background-color: rgba(255, 242, 236, 0.5)!important;
      }
      &:disabled {
        &:before {
          opacity: 0.4;
        }
        opacity: 1;
        color: rgba(0, 0, 0, 0.3)!important;
        border-bottom-color: rgba(255,113,63,.3)!important;
      }
    }
    &.yellow {
      border-bottom: 1.5px solid $yellow;
      color: $black!important;
      &:hover,
      &:focus{
        background-color: #FFF2DD!important;
        &:disabled {
          background-color: transparent!important;
        }
      }
      &:active {
        background-color: rgba(255, 242, 221, 0.5)!important;
      }
      &:disabled {
        &:before {
          opacity: 0.4;
        }
        opacity: 1;
        color: rgba(0, 0, 0, 0.3)!important;
        border-bottom-color: rgba(255,187,0,.3)!important;
      }
    }
    .btn-info-text {
      margin-bottom: 50px;
    }
    .button-info-text {
      text-align: left;
      top: 42px;
    }

  }
  &.btn-info-text {
    margin-bottom: 40px;
  }
  &.btn-big {
    width: 256px;
    max-width: 100%;
    height: 152px;
    border: solid 1px #d8d8d8;
    border-radius: 8px;
    box-shadow: 0 6px 6px 0 #d8d8d8!important;
    &:before {
      content: "";
      display: block;
      margin-bottom: 5px;
    }
	&.active,
    &:active {
      box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1);
      
	  &.yellow{
		background-color: #FFF2DD;
		border: 2px solid #fb0!important;
	  }
	  
	  &.orange{
		background-color: #FFF2EC;
		border: 2px solid #ff713f!important;
	  }
	  
	  &.blue{
		background-color: #DDE4FA;
		border: 2px solid #0f2566!important;
	  }
    }
  }
}

.blue-dark-bg {
  a {
    &.blue {
      color: $white;
      &:hover,
      &:focus {
        background: rgba(221, 228, 250, 0.2);
        text-decoration: none;
      }
      &:active {
        background: rgba(221, 228, 250, 0.1);
      }
    }
    &.orange {
      color: $white;
      &:hover,
      &:focus{
        background: rgba(255, 0, 0, 0.2);
        text-decoration: none;
      }
      &:active {
        background: rgba(255, 0, 0, 0.1);
      }
    }
    &.yellow {
      color: $white;
      &:hover,
      &:focus{
        background: rgba(255, 187, 0, 0.2);
        text-decoration: none;
      }
      &:active {
        background: rgba(255, 187, 0, 0.1);
      }
    }
  }

  button.btn,
  a.btn {
    .button-info-text {
      color: $white;
    }
    &.btn-primary {
      &.blue {
        color: $blue-dark!important;
        background-color: $white;
        border-color: $white;
        &:hover,
        &:focus {
          background-color: $blue-light;
          border-color: $blue-light;
          color: $blue-dark;
        }
        &:active {
          background-color: $white;
          border-color: $white;
        }
        &.anda-icon-plus {
          &:before {
            content: url(/assets/img/plus.svg);
          }
        }
        &:disabled {
          opacity: 1;
          color: transparent;
          background-color: rgba(255, 255, 255, 0.5)!important;
          border-color: rgba(129, 138, 162, 0.5)!important
        }
      }
      &.orange {
        &:hover,
        &:focus{
          background-color: $orange-light;
          border-color: $orange-light;
        }
        &:active{
          background-color: $orange-dark;
          border-color: $orange-dark;
        }
        &:disabled {
          border-color: transparent!important
        }
      }
      &.yellow {
        &:hover,
        &:focus{
          background-color: $yellow-light;
          border-color: $yellow-light;
        }
        &:active{
          background-color: $yellow-dark;
          border-color: $yellow-dark;
        }
        &:disabled {
          border-color: transparent!important
        }
      }
    }
    &.btn-secondary {
      color: white!important;
      background-color: $white!important;
      &.anda-icon-plus {
        &:before {
          content: url(/assets/img/plus-white.svg);
        }
      }
      &.blue {
        background-color: transparent!important;
        border-color: $white!important;
        &:hover,
        &:focus {
          background: rgba(221, 228, 250, 0.2)!important;
          &:disabled {
            background-color: transparent!important;
          }
        }
        &:active {
          background: rgba(221, 228, 250, 0.1)!important;
        }
        &:disabled {
          opacity: 1;
          color: rgba(255, 255, 255, 0.5)!important;
          border-color: hsla(0,0%,100%,.5)!important;
          &:before {
            opacity: 0.5;
          }
          .button-info-text {
            color: #fff!important;
          }
        }
      }
      &.orange {
        background-color: transparent!important;
        border-color: $orange;
        &:hover,
        &:focus {
          background: rgba(255, 0, 0, 0.2)!important;
          &:disabled {
            background-color: transparent!important;
          }
        }
        &:active {
          background: rgba(255, 0, 0, 0.1)!important;
        }
        &:disabled {
          opacity: 1;
          color: rgba(255, 255, 255, 0.5)!important;
          border-color: rgba(255, 113, 63, 0.5)!important;
          &:before {
            opacity: 0.5;
          }
          .button-info-text {
            color: #fff!important;
          }
        }
      }
      &.yellow {
        background-color: transparent!important;
        border-color: $yellow;
        &:hover,
        &:active,
        &:focus {
          background: rgba(255, 187, 0, 0.2)!important;
          &:disabled {
            background-color: transparent!important;
          }
        }
        &:active {
          background: rgba(255, 187, 0, 0.1)!important;
        }
        &:disabled {
          opacity: 1;
          color: rgba(255, 255, 255, 0.5)!important;
          border-color: rgba(255, 187, 0, 0.5)!important;
          &:before {
            opacity: 0.5;
          }
          .button-info-text {
            color: #fff!important;
          }
        }
      }
    }
    &.btn-tertiary {
      border-bottom: 1px solid #FFF;
      &.anda-icon-plus {
        &:before {
          content: url(/assets/img/plus-white.svg);
        }
      }
      &.blue {
        color: $white!important;
        &:hover,
        &:focus {
          background: rgba(221, 228, 250, 0.2)!important;
          &:disabled {
            background-color: transparent!important;
          }
        }
        &:active {
          background: rgba(221, 228, 250, 0.1)!important;
        }
        &:disabled {
          &:before {
            opacity: 0.4;
          }
          opacity: 1;
          color: rgba(255, 255, 255, 0.4)!important;
          border-bottom-color: rgba(255,255,255,.4)!important;

        }
      }
      &.orange {
        color: $white!important;
        border-bottom: 1px solid $orange;
        &:hover,
        &:focus{
          background: rgba(255, 0, 0, 0.2)!important;
          &:disabled {
            background-color: transparent!important;
          }
        }
        &:active {
          background: rgba(255, 0, 0, 0.1)!important;
        }
        &:disabled {
          &:before {
            opacity: 0.4;
          }
          opacity: 1;
          color: rgba(255, 255, 255, 0.5)!important;
        }
      }
      &.yellow {
        color: $white!important;
        border-bottom: 1px solid $yellow;
        &:hover,
        &:focus{
          background: rgba(255, 187, 0, 0.2)!important;
          &:disabled {
            background-color: transparent!important;
          }
        }
        &:active {
          background: rgba(255, 187, 0, 0.1)!important;
        }
        &:disabled {
          &:before {
            opacity: 0.4;
          }
          opacity: 1;
          color: rgba(255, 255, 255, 0.5)!important;
        }
      }
    }
  }
}
.floating-action-button {
  width: 124px;
  height: 124px;
  background-color: #0F2566;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1);
  &:hover,
  &:focus {
    background-color: #3F5185;
    border-color: #3F5185;
  }
  &:active {
    background-color: $blue-dark;
    border-color: $blue-dark;
  }
  a {
    color: #FFF;
  }
  &.orange {
    background-color: #F36330;
    a {
      color: #000;
    }
    &:hover,
    &:focus{
      background-color: $orange-light;
      border-color: $orange-light;
    }
    &:active{
      background-color: $orange-dark;
      border-color: $orange-dark;
    }
  }
  &.yellow {
    a {
      color: #000;
    }
    &:hover,
    &:focus{
      background-color: $yellow-light;
      border-color: $yellow-light;
    }
    &:active{
      background-color: $yellow-dark;
      border-color: $yellow-dark;
    }
    background-color: #FFBB00;
  }

  .icon-fab {
    font-size: 18px;
    line-height: 1.2;
    font-weight: bold;
    margin-top: -6px;
    &:before {
      font-size: 30px;
      font-weight: initial;
      display: block;
    }
    .nunito {
      font-family: $nunito;
    }
  }
}