#buttons,
#buttons-dark,
#eingabefelder,
#tabelle,
#miscellaneous-part1,
#miscellaneous-part2,
#miscellaneous-part3,
#miscellaneous2,
#selection,
#icons-presentation {
  padding: 2em 1em;
  > hr {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  #miscellaneous-nav {
    min-height: 250px;
  }
}
.anda-navigation {
 li {
   padding-left: 20px;
 }
}

.invalid-feedback {
  display: block!important;
}

h5 {
  font-weight: 700;
  margin-top: 2.5rem;
}

.icons-temp {
  h1 {
    font-size: 1.5em;
    font-weight: normal;
  }
  small {
    font-size: .66666667em;
  }
  a {
    color: #e74c3c;
    text-decoration: none;
  }
  a:hover, a:focus {
    box-shadow: 0 1px #e74c3c;
  }
  .bshadow0, input {
    box-shadow: inset 0 -2px #e7e7e7;
  }
  input:hover {
    box-shadow: inset 0 -2px #ccc;
  }
  input, fieldset {
    font-family: sans-serif;
    font-size: 1em;
    margin: 0;
    padding: 0;
    border: 0;
  }
  input {
    color: inherit;
    line-height: 1.5;
    height: 1.5em;
    padding: .25em 0;
  }
  input:focus {
    outline: none;
    box-shadow: inset 0 -2px #449fdb;
  }
  .glyph {
    font-size: 1rem;
    width: 15em;
    padding-bottom: 1em;
    margin-right: 4em;
    margin-bottom: 1em;
    float: left;
    overflow: hidden;
  }
  .liga {
    width: 80%;
    width: calc(100% - 2.5em);
  }
  .talign-right {
    text-align: right;
  }
  .talign-center {
    text-align: center;
  }
  .bgc1 {
    background: #f1f1f1;
  }
  .fgc1 {
    color: #999;
  }
  .fgc0 {
    color: #000;
  }
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .mvm {
    margin-top: .75em;
    margin-bottom: .75em;
  }
  .mtn {
    margin-top: 0;
  }
  .mtl, .mal {
    margin-top: 1.5em;
  }
  .mbl, .mal {
    margin-bottom: 1.5em;
  }
  .mal, .mhl {
    margin-left: 1.5em;
    margin-right: 1.5em;
  }
  .mhmm {
    margin-left: 1em;
    margin-right: 1em;
  }
  .mls {
    margin-left: .25em;
  }
  .ptl {
    padding-top: 1.5em;
  }
  .pbs, .pvs {
    padding-bottom: .25em;
  }
  .pvs, .pts {
    padding-top: .25em;
  }
  .unit {
    float: left;
  }
  .unitRight {
    float: right;
  }
  .size1of2 {
    width: 50%;
  }
  .size1of1 {
    width: 100%;
  }
  .clearfix:before, .clearfix:after {
    content: " ";
    display: table;
  }
  .clearfix:after {
    clear: both;
  }
  .hidden-true {
    display: none;
  }
  .textbox0 {
    width: 3em;
    background: #f1f1f1;
    padding: .25em .5em;
    line-height: 1.5;
    height: 1.5em;
  }
  #testDrive {
    display: block;
    padding-top: 24px;
    line-height: 1.5;
  }
  .fs0 {
    font-size: 1rem;
  }
  .fs1 {
    font-size: 32px;
  }
}
.dz-error-mark {
  display: none!important;
}