table {
  font-size: 1rem;
  th {
    font-size: 1rem;
    font-weight: 900;
    border-top: 0!important;
    border-bottom: 1.5px solid #000!important;
  }
}
p {
  del {
    color: #7F7F7F;
  }
}
/*
ol.anda-ol {
  padding: 0;
  li {
    counter-increment: item;
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 2rem;
    text-indent: -0.9rem;
    line-height: 1.8;
    &::before {
      content: counter(item) '.';
      display: inline-block;
      font-weight: bold;
      padding-right: 12px;
      text-align: right;
      width: 1.5rem;
    }
  }
}

 */
/*
ol.anda-ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  padding-left: 0;
  ol {
    padding-left: 40px;
  }
  li {
    counter-increment: my-awesome-counter;
    &:before {
      content: counter(my-awesome-counter) ". ";
      color: #000;
      font-weight: bold;
      padding-right: 7px;
    }
  }
}
*/
ol.anda-ol { counter-reset: item }
.anda-ol li{ display: block }
.anda-ol li:before { content: counters(item, ".") ". "; counter-increment: item;font-weight: bold; }


ul.anda-ul {
  padding-left: 16px;
  li {
    padding-left: 8px;
    line-height: 1.8;
  }
  &.blue {
    list-style-image: url(/assets/img/bullet_point_blue.svg);
  }
  &.orange {
    list-style-image: url(/assets/img/bullet_point_orange.svg);
  }
  &.yellow {
    list-style-image: url(/assets/img/bullet_point_yellow.svg);
  }
}