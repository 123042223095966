.wickedpicker {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 auto;
  font-size: 1rem;
  display: none;
  width: 232px;
  height: 180px;
  border: 1.5px solid #000;
  background: #fff;
  border-top: unset;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.wickedpicker__controls__control--hours,
.wickedpicker__controls__control--minutes {
  &:focus,
  &:active {
    outline: unset;
  }
}


[aria-showingpicker=true] {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom: 1px solid #ababab !important;
}

.wickedpicker__title {
  display: none;
}

.wickedpicker__close {
  -webkit-transform: translateY(-25%);
  -moz-transform: translateY(-25%);
  -ms-transform: translateY(-25%);
  -o-transform: translateY(-25%);
  transform: translateY(-25%);
  position: absolute;
  top: 25%;
  right: 10px;
  color: #34495e;
  cursor: pointer;
}

.wickedpicker__close:before {
  content: '\00d7';
}

.wickedpicker__controls {
  padding: 54px 0;
  line-height: normal;
  margin: 0;
}

.wickedpicker__controls__control, .wickedpicker__controls__control--separator {
  vertical-align: middle;
  display: inline-block;
  font-size: inherit;
  margin: 0 auto;
  width: 35px;
  letter-spacing: 1.3px;
}

.wickedpicker__controls__control-up, .wickedpicker__controls__control-down {
  color: #34495e;
  position: relative;
  display: block;
  margin: 3px auto;
  font-size: 1.12rem;
  cursor: pointer;
}

.wickedpicker__controls__control--separator {
  width: 5px;
}

.text-center, .wickedpicker__title, .wickedpicker__controls, .wickedpicker__controls__control, .wickedpicker__controls__control--separator, .wickedpicker__controls__control-up, .wickedpicker__controls__control-down {
  text-align: center;
}

.hover-state {
  color: #3498db;
}

.timepicker {
  font-size: 1.12rem;
  height: 55px;
  color: #000;
  border-radius: 4px;
  border: 1.5px solid #000;
  padding: 13px 20px !important

}