.dropdown {
  a.dropdown-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
  }

  .dropdown-item:active,
  .dropdown-item:hover,
  .dropdown-item.active {
    color: #000;
    text-decoration: none;
    background-color: #DDE4FA;
    cursor: pointer;
  }

  .dropdown-item:focus {
    border: 0;
  }

  &.disabled {
    opacity: 0.7;
  }

  &.orange {
    .dropdown-item:active,
    .dropdown-item:focus,
    .dropdown-item:hover,
    .dropdown-item.active {
      background-color: #FFF2EC;
    }
  }

  &.yellow {
    .dropdown-item:active,
    .dropdown-item:focus,
    .dropdown-item:hover,
    .dropdown-item.active {
      background-color: #FFF2DD;
    }
  }
}

.search-basic {
  .dropdown-menu.show {
    margin-top: -55px;
    border-top: 1.5px solid #000;
    border-radius: 4px;
    .bs-searchbox {
      border-bottom: 1px solid #ababab !important;
      padding: 8px 0!important;
      .form-control {
        &:before {
          display: none;
        }
      }
    }
    .inner.show {
      border-top: 0 !important;
        .form-control {
          padding: 0;
          border: 0;
          &:focus {
            border: 0 !important;
            box-shadow: unset !important;
          }
        }
    }
  }
}

.bs-searchbox .form-control:focus {
  box-shadow: unset;
  border: 0;
}

.dropdown.show .btn {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

.dropup.show button {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top: 2px solid transparent !important;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

/*
.dropdown .dropdown-toggle:after {
  position: absolute;
  right: 20px !important;
  top: 24px !important;
}

 */
/*
.dropdown-menu.show {
  top: -4px !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-color: #000;
  border-top-color: #D8D8D8;
  max-height: 192px;
  overflow-y: auto;
}

 */

label.form-check-label {
  font-weight: 600;
  font-size: 1rem;
}
div.phone-number {
  margin-bottom: 20px;
  width: max-content;
  a.tooltip-a {
    display: block;
    text-align: right;
    width: max-content;
    margin-left: auto;
  }
}

.custom-control-label:before {
  border: 1.5px solid #000 !important;
  outline: none !important;
  box-shadow: none !important;
}

.custom-switch .custom-control-label:after {
  background-color: #000;
  outline: none !important;
  box-shadow: none !important;
}

.custom-control-label:before {
  height: 22px !important;
  width: 42px !important;
  border-radius: 10px !important;
  top: 5px;
}

.custom-switch .custom-control-label:after {
  background-color: #fff;
  border: 1.5px solid #000;
  width: 26px !important;
  height: 26px !important;
  border-radius: 63%;
}

.custom-switch .custom-control-label:after {
  top: calc(.25rem + -1px);
  left: calc(-2.25rem + 0px);
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: #000 !important;
  background-color: #000 !important;
  outline: none !important;
  box-shadow: none !important;
}

.custom-switch .custom-control-label:after {
  right: calc(-2.25rem + 1px);
  left: unset;
  cursor: pointer;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  transform: translateX(1.2rem);
}

.custom-switch .custom-control-label:before {
  right: -3.25rem;
  left: unset;
}

label.custom-control-label {
  margin-left: -30px;
}


.form-check {
  input[type="checkbox"],
  input[type="radio"] {
    display: none;

    &:empty ~ label {
      position: relative;
      text-indent: 2.25em;
      margin-top: 5px;
      cursor: pointer;

      &:before {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        content: '';
        border: 1px solid #000;
        width: 21px;
        height: 21px;
        border-radius: 4px;
      }
      &:hover {
        &:before {
          border-width: 1.5px;
        }
      }
    }

    &:checked ~ label {
      &:before {
        content: url(/assets/img/tick-white.svg);
        text-indent: 0;
        color: #fff;
        background-color: #000;
        border-width: 2px;
      }
    }

    &:disabled ~ label {
      opacity: 0.2;
      color: #000;
    }
  }
}

.form-check {
  input[type="radio"] {
    &:empty ~ label {
      &:before {
        border-radius: 50%;
      }
    }

    &:checked ~ label {
      &:before {
        content: '';
        background: url(/assets/img/radio-dot.svg) CENTER CENTER NO-REPEAT;
        color: #fff;
      }
    }
  }
}

.dd {
  display: inline-block;
  border: 1px solid #B2B2B2;
  background-color: transparent;
  width: 130px !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  .ddTitle {
    height: 56px;
    background: none;

    .ddTitleText {
      padding: 16px 28px;

      .description {
        display: none !important;
      }
    }
  }

  .divider {
    display: none;
  }

  &:focus {
    outline: none;
  }

  span.ddArrow.arrowoff:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0px;
    border-left: 0.3em solid transparent;
  }
}

.input-phone-number {
  display: inline-block;
  width: calc(100% - 150px);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -5px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  padding: 16px 20px !important;
  height: initial !important;
  font-size: 16px !important;
  border-color: #B2B2B2 !important;

  &:placeholder-shown {
    border-width: 1px;
  }
}

select {
  border-color: #6c757d !important;
  color: #000 !important;
  max-width: 300px;
  font-weight: 700 !important;

  &:focus {
    box-shadow: none !important;
  }
}

.btn-big {
  width: 256px;
  max-width: 100%;
  height: 152px;
  border: solid 1px #d8d8d8;
  border-radius: 8px;
  box-shadow: 0 6px 6px 0 #d8d8d8 !important;
  text-indent: 0 !important;
  text-align: center;
  color: #000;

  span {
    display: block;
    font-size: 57px;
    margin-top: 37px;
    margin-bottom: 10px;
  }

  &:before {
    content: "" !important;
    display: block !important;
    width: 0 !important;
    height: 0 !important;
    border: 0 !important;
  }

  &:focus {
    outline: none !important;
  }

  &:hover {
    border: solid 1px #000000 !important;
  }
}

.form-check input[type=checkbox]:disabled ~ label.btn-big,
.form-check input[type=checkbox]:disabled ~ label.btn-big:hover {
  border: solid 1px #6D6D6D !important;
  cursor: default;
}


.form-check input[type=checkbox]:checked ~ label.btn-big:before {
  content: "" !important;
  background: none !important;
}

.blue-color.form-check input[type=checkbox]:checked ~ label.btn-big {
  border: solid 2px #0f2566 !important;
}
.orange-color.form-check input[type=checkbox]:checked ~ label.btn-big {
  border: solid 2px #ff713f !important;
}
.yellow-color.form-check input[type=checkbox]:checked ~ label.btn-big {
  border: solid 2px #ffbb00 !important;
}

.form-check.blue-color input[type=checkbox]:checked ~ label.btn-big {
  background-color: #DDE4FA;
}

.form-check.orange-color input[type=checkbox]:checked ~ label.btn-big {
  background-color: #FFF2EC;
}

.form-check.yellow-color input[type=checkbox]:checked ~ label.btn-big {
  background-color: #FFF2DD;
}

.form-check input[type=checkbox]:active ~ label.btn-big {
  border: solid 2px #000000 !important;
}

.bootstrap-select {
  .btn {
    border-width: 1.5px;
  }

  .bs-placeholder {
    border-width: 1px!important;
  }
  .btn[aria-expanded="true"]{
    border-width: 1.5px!important;
  }

  .dropdown-menu {
    margin: -2px 0 0px 0;
    border-color: #000;
    border-top-color: #ababab;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-width: 1.5px;
    border-top-width: 1px;
    padding: 0!important;

    .form-control {
      border: 0;
    }

    &[x-placement="top-start"] {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 1px solid #ababab;
      border-top: 1.5px solid #000;
      margin: 0;

    }

    .inner.show {
      max-height: 180px !important;
    }
  }

  li {
    &:focus {
      outline: none;
    }
  }
}
.no-results {
  background: transparent!important;
  border: 0;
  margin: 0!important;
  padding: 10px 15px!important;
  .kein-ergebnis {
    padding: 10px 0;
    border-bottom: 1px solid #7F7F7F;
    color: #7F7F7F;
  }
  .add-missing-text {
    padding: 10px 0;
    color: #000;
    &:hover {
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.blue {
  .category-tag {
    background-color: $blue-light;
  }
}
.orange {
  .category-tag {
    background-color: #fff2ec;
  }
}
.yellow {
  .category-tag {
    background-color: #fff2dd;
  }
}
.category-tag {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.78;
  color: #000000;
  border-radius: 20px;
  padding: 4px 22px;
  width: fit-content;
  a {
    color: #000;
  }
  span {
    margin-right: 9px;
  }
}