form {
  .form-control {
    color: #000;
  }

  label {
    font-size: 1.2 rem;
    margin-bottom: 10px;
    line-height: 18px;
	font-weight: 700;
  }

  input {
    padding: 13px 20px !important;
    height: initial !important;
    font-size: 18px !important;
    border-color: #ABABAB !important;
    border-style: solid;
    border-radius: 4px;

    &:valid {
      border-width: 1.5px;
      border-color: #000000 !important;
    }


    &.placeholder-shown {
      border-width: 1px;
    }



    &[type=tel] {
      padding-left: 32px !important;
    }
  }

  textarea {
    //resize: none;
    font-size: 16px !important;
    border-color: #ABABAB !important;

    &:valid {
      border-width: 2px;
      border-color: #ABABAB !important;
    }


    &.placeholder-shown {
      border-width: 1px;
    }


  }

  .blue {
    textarea {
      &:valid {
        border-color: $blue !important;
      }
    }
  }

  .orange {
    textarea {
      &:valid {
        border-color: $orange !important;
      }
    }
  }

  .yellow {
    textarea {
      &:valid {
        border-color: $yellow !important;
      }
    }
  }

  /*
  input[type=text],
  textarea {
    &::placeholder {
      color: transparent !important;
    }
  }

   */

  small {
    font-size: 1rem;
    color: #5F5F5F;
  }

  .form-control {
    &:focus {
      color: $black;
      background-color: #fff;
      outline: 0;
      box-shadow: unset;
      border-width: 2px !important;
    }

    &:disabled {
      background-color: initial !important;
      opacity: 0.4;
    }
  }

  &.blue {
    .form-control {
      &:focus {
        border-color: $blue !important;
      }

      &:not(.placeholder-shown) {
        border-color: $black !important;

        &.overmax {
          border-color: #F20000 !important;
        }
      }
    }
  }

  &.orange {
    .form-control {
      &:focus {
        border-color: $orange !important;
      }

      &:not(.placeholder-shown) {
        border-color: $black !important;

        &.overmax {
          border-color: #F20000 !important;
        }
      }
    }
  }

  &.yellow {
    .form-control {
      &:focus {
        border-color: $yellow !important;
      }

      &:not(.placeholder-shown) {
        border-color: $black !important;

        &.overmax {
          border-color: #F20000 !important;
        }
      }
    }
  }

  .form-text {
    margin-top: 10px;
    line-height: 18px;
    font-size: 16px !important;

    &.text-muted {
      color: #7f7f7f !important;
    }
  }

  .invalid-feedback {
    font-size: 16px !important;
    line-height: 18px;
    margin-top: 10px;
    color: #F20000;
  }
}

.bootstrap-maxlength {
  font-size: 14px;
}

.disabledTextInput {
  opacity: 0.4;
}

.form-control {
  &:invalid,
  &.invalid,
  &.overmax {
    border-color: #F20000 !important;
    background: rgba(255, 0, 0, 0.1) !important;
    border-width: 2px;

  }
}

.tooltip {
  font-family: $nunito !important;

  &.show {
    opacity: 1 !important;
  }

  .arrow {
    &:before {
      border-top-color: #DDE4FA !important;
    }
  }

  .tooltip-inner {
    font-family: $nunito !important;
    background-color: #DDE4FA;
    padding: 20px;
    max-width: 400px;
    color: $black;
    text-align: left;

    h6 {
      font-weight: 600;
      font-size: 1.25rem;
    }

    font-size: 1rem;
  }
}

.tooltip {
  &.tooltip-blue {
    .arrow {
      &:before {
        border-top-color: #DDE4FA !important;
        border-bottom-color: #DDE4FA !important;
      }
    }

    .tooltip-inner {
      background-color: #DDE4FA;
    }
  }

  &.tooltip-orange {
    .arrow {
      &:before {
        border-top-color: #FFF2EC !important;
        border-bottom-color: #FFF2EC !important;
      }
    }

    .tooltip-inner {
      background-color: #FFF2EC;
    }
  }

  &.tooltip-yellow {
    .arrow {
      &:before {
        border-top-color: #FFF2DD !important;
        border-bottom-color: #FFF2DD !important;
      }
    }

    .tooltip-inner {
      background-color: #FFF2DD;
    }
  }

  .icon-close_l {
    position: absolute;
    top: 25px;
    right: 18px;
  }

  &:before {
    content: url(/assets/img/close.svg);
    width: 16px;
    height: 16px;
    position: absolute;
    right: 5px;
    top: 10px;
    cursor: pointer;
  }
}

.bootstrap-maxlength {
  right: 0;
  left: auto !important;
}